import React, { useCallback, useMemo } from 'react';
import { showModal } from 'shared/foreground/actions/modals';
import { clearAllLocalData } from 'shared/foreground/clearLocalData';
import { globalState } from 'shared/foreground/models';
import background from 'shared/foreground/portalGates/toBackground';
import { setTheme, toggleAutoAdvance } from 'shared/foreground/stateUpdaters/clientStateUpdaters/other';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import { DisplayTheme } from 'shared/types';
import { isDevOrTest } from 'shared/utils/environment';
import getDocumentLocationDisplayName from 'shared/utils/getDocumentLocationDisplayName';

import Button from '../Button';
import DefaultViewDropdown from '../Dropdown/DefaultViewDropdown';
import SidePanelsVisibilityDropdown from '../Dropdown/SidePanelsVisibilityDropdown';
import DarkThemeIcon from '../icons/DarkThemeIcon';
import LightThemeIcon from '../icons/LightThemeIcon';
import SystemThemeIcon from '../icons/SystemThemeIcon';
import Switch from '../Switch';
import { AccountSettingsPage, List, ListItem, Section } from './AccountSettingsPage';
import styles from './PreferencesPage.module.css';

export const PreferencesPage = () => {
  const currentTheme = globalState(useCallback((state) => state.client.theme, []));

  return (
    <AccountSettingsPage title="Preferences">
      <List>
        <ListItem title="Interface theme" subtitle="Customize your interface color theme.">
          <div className={styles.themePicker}>
            <ThemePicker
              isActive={currentTheme === DisplayTheme.Light || currentTheme === DisplayTheme.Default}
              theme={DisplayTheme.Light}
              icon={<LightThemeIcon />}
              className={styles.lightTheme}
            />
            <ThemePicker
              isActive={currentTheme === DisplayTheme.Dark}
              theme={DisplayTheme.Dark}
              icon={<DarkThemeIcon className={styles.darkThemeIcon} />}
              className={styles.darkTheme}
            />
            <ThemePicker
              isActive={currentTheme === DisplayTheme.System}
              theme={DisplayTheme.System}
              icon={<SystemThemeIcon />}
              className={styles.systemTheme}
            />
          </div>
        </ListItem>

        <DefaultView />

        <AutoAdvance />

        <LibraryConfiguration />

        <PanelsVisibility />

        <KeyboardShortcuts />

        <GhostreaderPrompts />

        {isDevOrTest && (
          <Section contentBoxClassName={styles.developerActionsWrapper} id="developer" title="Developer">
            <Button variant="secondary" onClick={clearAllLocalData}>
              Clear all local data
            </Button>
            <Button variant="secondary" to="/database-query-optimizer">
              Database Query Optimizer
            </Button>
            <Button variant="secondary" onClick={background.downloadSyncerCacheForDebugging}>
              Download debugging data
            </Button>
            <Button variant="secondary" to="/database-explorer">
              Database Explorer
            </Button>
          </Section>
        )}
      </List>
    </AccountSettingsPage>
  );
};

const ThemePicker = ({
  icon,
  isActive,
  theme,
  className = '',
}: { icon: JSX.Element; isActive: boolean; theme: DisplayTheme; className?: string }) => {
  return (
    <Button
      className={`${className} ${styles.theme} ${isActive ? styles.themeIsActive : ''}`}
      variant="unstyled"
      onClick={() => setTheme(theme, { userInteraction: 'click' })}
    >
      {icon}
    </Button>
  );
};

const DefaultView = () => {
  return (
    <ListItem title="Default view" subtitle="Default view that opens when you launch Reader.">
      <div className={styles.defaultView}>
        <DefaultViewDropdown />
      </div>
    </ListItem>
  );
};

const AutoAdvance = () => {
  const autoAdvance = globalState(useCallback((state) => state.client.autoAdvance, []));

  return (
    <ListItem
      title="Auto-advance"
      subtitle="Proceed to the next document after taking an action, instead of returning to the list."
    >
      <div className={styles.autoAdvance}>
        <Switch
          rootProps={{
            checked: autoAdvance,
            onCheckedChange: () => toggleAutoAdvance({ userInteraction: 'click', showToast: true }),
            id: 'extension-auto-highlighting-checkbox',
          }}
        />
      </div>
    </ListItem>
  );
};

const PanelsVisibility = () => {
  return (
    <ListItem
      title="Side panel visibility by default"
      subtitle="Determines whether the side panels are hidden or visible when you first enter reading view."
    >
      <div className={styles.sidePanelVisibility}>
        <SidePanelsVisibilityDropdown />
      </div>
    </ListItem>
  );
};

const KeyboardShortcuts = () => {
  return (
    <ListItem title="Keyboard shortcuts" subtitle="Personalize keyboard shortcuts to your own liking.">
      <div className={styles.customize}>
        <Button variant="unstyled" to="/preferences/shortcuts">
          Customize
        </Button>
      </div>
    </ListItem>
  );
};

const GhostreaderPrompts = () => {
  return (
    <ListItem
      title="Ghostreader prompts"
      subtitle="Personalize Ghostreader prompts to serve your unique use cases."
    >
      <div className={styles.customize}>
        <Button variant="unstyled" to="/preferences/ghostreader">
          Customize
        </Button>
      </div>
    </ListItem>
  );
};

const LibraryConfiguration = () => {
  const documentLocations = useDocumentLocations();
  const currentSetting = useMemo(
    () => getDocumentLocationDisplayName(documentLocations),
    [documentLocations],
  );

  return (
    <ListItem title="Library configuration" subtitle="Change your desired workflow">
      <div className={styles.defaultView}>
        <Button
          variant="unstyled"
          onClick={() => showModal({ id: 'library-locations' }, { userInteraction: 'unknown' })}
        >
          {currentSetting}
        </Button>
      </div>
    </ListItem>
  );
};
